@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Inter', sans-serif;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Sora', sans-serif;
  }
}

/* styles.css */

.bio-markdown h1,
.bio-markdown h2,
.bio-markdown h3,
.bio-markdown h4,
.bio-markdown h5,
.bio-markdown h6 {
  @apply text-gray-900 my-4;
}

.bio-markdown h1 {
  @apply text-2xl;
}

.bio-markdown h2 {
  @apply text-xl;
}

.bio-markdown p {
  @apply my-2;
}

.bio-markdown a {
  @apply text-blue-600 underline;
}

.bio-markdown ul,
.bio-markdown ol {
  @apply list-decimal pl-5;
}

.bio-markdown blockquote {
  @apply border-l-4 border-gray-300 pl-4 italic;
}

/* ... Add other styles as needed */
